import { ref } from 'vue';
import { defineStore } from 'pinia';
import { me } from '@/js/Helpers/Session';
import UserApi from '@/js/Api/User';
import StorageApi from '@/js/Api/Storage';
import QuoteGroupApi from '@/js/Api/QuoteGroup';
import ClientApi, { Client } from '@/js/Api/Client';
import PolicyApi, { Policy } from '@/js/Api/Policy';
import TicketApi, { Ticket } from '@/js/Api/Ticket';
import useTicketsActions from './actions/useTicketsActions';
import swr from './swr';

type Arg = Pick<Client, 'id'>;

export default defineStore('client', () => {
  const deleted = ref(false);

  const client = ref<Client | null>(null);

  const referralSource = ref(null);

  const producer = ref(null);

  const customerService = ref(null);

  const quotingSpecialist = ref(null);

  const relatedClients = ref(null);

  const addresses = ref(null);

  const contacts = ref(null);

  const policies = ref(null);

  const quoteGroups = ref(null);

  const createdByUser = ref(null);

  const attachmentsTree = ref(null);

  const policy = ref<Policy | null>(null);

  const tickets = ref<Ticket[] | null>(null);
  const currentTicketId = ref<number | null>(null);

  function swrClient({ id }: Arg) {
    deleted.value = false;
    return swr({
      ref: client,
      invalidation: id,
      fetcher: () => ClientApi.find({ id }),
    });
  }

  function swrReferralSource({ id }: Arg) {
    return swr({
      ref: referralSource,
      invalidation: id,
      fetcher: () => ClientApi.referralSource.find({ id }),
    });
  }

  function swrProducer({ id }: Arg) {
    return swr({
      ref: producer,
      invalidation: id,
      fetcher: () => ClientApi.producer.find({ id }),
    });
  }

  function swrCustomerService({ id }: Arg) {
    return swr({
      ref: customerService,
      invalidation: id,
      fetcher: () => ClientApi.customerService.find({ id }),
    });
  }

  function swrQuotingSpecialist({ id }: Arg) {
    return swr({
      ref: quotingSpecialist,
      invalidation: id,
      fetcher: () => ClientApi.quotingSpecialist.find({ id }),
    });
  }

  function swrRelatedClients({ id }: Arg) {
    return swr({
      ref: relatedClients,
      invalidation: id,
      fetcher: () => ClientApi.relatedClients.all({ id }),
    });
  }

  function swrAddresses({ id }: Arg) {
    return swr({
      ref: addresses,
      invalidation: id,
      fetcher: () => ClientApi.addresses.all({ id }),
    });
  }

  function swrContacts({ id }: Arg) {
    return swr({
      ref: contacts,
      invalidation: id,
      fetcher: () => ClientApi.contacts.all({ id }),
    });
  }

  function swrPolicies({ id }: { id: number }) {
    return swr({
      ref: policies,
      invalidation: id,
      fetcher: () =>
        PolicyApi.all({
          queryBuilder: {
            filters: {
              client_id: id,
              with_trashed: me.permissions['policies_deleted.get'] === true ? true : undefined,
            },
            includes: [
              'agency',
              'carrier',
              'finance_company',
              'intakes.intake_body',
              'cancellation',
              'cancellations',
              'last_verified_by_user',
              'renews_policies',
              'renewed_by_policy',
            ],
          },
        }),
    });
  }

  function swrQuoteGroups({ id }: { id: number }) {
    return swr({
      ref: quoteGroups,
      invalidation: id,
      fetcher: () =>
        QuoteGroupApi.all({
          queryBuilder: {
            filters: {
              client_id: id,
              with_trashed: me.permissions['quote_groups_deleted.get'] === true ? true : undefined,
            },
            includes: ['intakes.intake_body', 'intakes.quotes.carrier'],
          },
        }),
    });
  }

  function swrCreatedByUser({ id }: Arg) {
    return swr({
      ref: createdByUser,
      invalidation: id,
      fetcher: () => UserApi.find({ id }),
    });
  }

  function swrAttachmentsTree({ id }: Arg) {
    return swr({
      ref: attachmentsTree,
      invalidation: id,
      fetcher: () => StorageApi.clientTree({ clientId: id }),
    });
  }

  function swrTickets({ id }: Arg) {
    return swr({
      ref: tickets,
      invalidation: id,
      fetcher: () =>
        TicketApi.all({
          queryBuilder: {
            sorts: [
              {
                name: 'created_at',
                order: 'asc',
              },
            ],
            filters: {
              client_id: id,
            },
            includes: [
              'target_tickets',
              'merged_tickets',
              'notes.unsaved_text',
              'notes.watchers',
              'notes.created_by',
              'notes.assigned_to',
              'notes.mailbox_message',
              'notes.followup_created_by',
              'notes.notes.watchers',
              'notes.notes.created_by',
              'notes.notes.assigned_to',
              'notes.notes.followup_created_by',
            ],
          },
        }),
    });
  }

  function updateClient(value) {
    Object.assign(client.value, value);
  }

  return {
    client,
    deleted,
    producer,
    contacts,
    addresses,
    policies,
    referralSource,
    relatedClients,
    policy,
    tickets,
    currentTicketId,
    swrClient,
    swrProducer,
    swrAddresses,
    swrContacts,
    swrReferralSource,
    swrCustomerService,
    swrQuotingSpecialist,
    swrRelatedClients,
    swrPolicies,
    swrQuoteGroups,
    swrCreatedByUser,
    swrAttachmentsTree,
    swrTickets,
    updateClient,
    ...useTicketsActions(tickets),
  };
});
